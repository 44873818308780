@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .disabled {
    opacity: 0.65;
    pointer-events: none;
  }

  .border-1 {
    border-width: 1px;
  }

  .border-b-1 {
    border-bottom-width: 1px;
  }

  .basis-100 {
    flex-basis: 25rem;
  }

  .locked {
    opacity: 0.65;
    cursor: default;
  }

  .locked input, .locked select, .locked textarea {
    pointer-events: none;
  }
}

@layer components {

  /* Default buttons */
  .btn-primary {
    @apply text-sm text-white font-medium rounded-lg border-2 border-primary-900 bg-primary-900
    hover:border-primary-800 hover:bg-primary-800 px-4 py-2 cursor-pointer;
  }

  .btn-secondary {
    @apply text-sm text-white font-medium rounded-lg border-2 border-primary-700 bg-primary-700
    hover:border-primary-600 hover:bg-primary-600 px-4 py-2 cursor-pointer;
  }

  .btn-primary-hollow {
    @apply text-sm text-primary-900 font-medium rounded-lg border-2 border-primary-900
    bg-transparent hover:bg-primary-800 hover:border-primary-800 hover:text-white px-4 py-2
    cursor-pointer;
  }

  /* Danger buttons */
  .btn-danger {
    @apply text-sm text-white font-medium rounded-lg border-2 border-red-700 bg-red-700
    hover:border-red-600 hover:bg-red-600 px-4 py-2 cursor-pointer;
  }

  .btn-danger-secondary {
    @apply text-sm text-white font-medium rounded-lg border-2 border-red-500 bg-red-500
    hover:border-red-400 hover:bg-red-400 px-4 py-2 cursor-pointer;
  }

  .btn-danger-hollow {
    @apply text-sm text-red-700 font-medium rounded-lg border-2 border-red-700
    bg-transparent hover:bg-red-700 hover:text-white px-4 py-2 cursor-pointer;
  }

  /* Disabled buttons */
  .btn-disabled {
    @apply text-sm text-white font-medium rounded-lg opacity-60 cursor-not-allowed px-4 py-2
    disabled hover:bg-gray-400;
  }

  /* Inputs */
  .input-base {
    @apply mt-1 block w-full border-gray-300 rounded-md shadow-sm;
  }

  .input-text {
    @apply form-input input-base focus:border-indigo-500 focus:ring focus:ring-indigo-500
    focus:ring-opacity-50;
  }

  .input-textarea {
    @apply form-textarea input-base focus:border-indigo-500 focus:ring focus:ring-indigo-500
    focus:ring-opacity-50;
  }

  .input-checkbox {
    @apply form-checkbox h-5 w-5 text-indigo-600;
  }

  .input-select {
    @apply form-select input-base focus:border-indigo-500 focus:ring focus:ring-indigo-500
    focus:ring-opacity-50;
  }

  .input-date {
    @apply input-base focus:border-indigo-500 focus:ring focus:ring-indigo-500
    focus:ring-opacity-50;
    type: date;
  }

  .input-datetime {
    @apply input-base focus:border-indigo-500 focus:ring focus:ring-indigo-500
    focus:ring-opacity-50;
    type: datetime-local;
  }

  .input-number {
    @apply input-base focus:border-indigo-500 focus:ring focus:ring-indigo-500
    focus:ring-opacity-50;
  }

  .input-toggle {
    @apply relative w-11 h-6 bg-gray-200 rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full
    peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white
    after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600
    peer-checked:bg-primary-700
  }
}

/* https://github.com/django-crispy-forms/crispy-tailwind/issues
there are a few issues reported on the double arrow and select not being rendered properly.
fixes for this is incoming in a near-future release. in the meantime, hide the extra arrow here.
 */
select ~ .pointer-events-none {
  display: none !important;
}

[x-cloak] {
  display: none;
}

/* Hide scrollbar for Webkit browsers */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.no-scroll {
  overflow: hidden;
}

/* Fix for slideover vertical scrolling. */
.slideover-title-wrapper {
  height: 3rem;
}

.slideover-body-wrapper {
  height: calc(100% - 3rem);
}
